<template>
  <div>
    <!-- 导航栏 -->
    <NavBar />

    <div class="intro page-last">
      <div class="intro__header">天存简介</div>
      <div class="intro__main">
        <p>
          上海天存信息技术有限公司是一家专注于 Web 应用安全领域的软件产品提供商，自 2003 年以来已经为超过 3000 家客户提供了专业化的产品和服务，成为国内 Web 应用安全领域的重要厂商。
        </p>
        <p>
          天存信息核心技术团队是国内最早从事 Web 安全研究的团队之一，从 1999 年开始深入剖析 Web 浏览器/服务器软件以及 HTTP/HTTPS 协议，其自主研发的 PSP 安全 Web 服务器在 2000 年获得国家 863 计划的支持，填补了当时中国在 Web 安全领域的技术空白。
        </p>
        <p>
          2003 年，天存信息将 Web 领域特别是 Web 服务器软件方面的深厚积累加以提炼，推出国内首个网页防篡改系统——iGuard 网页防篡改系统。该产品因其技术领先、高效稳定、广谱适应而在政府、金融、电信运营商、新闻传媒、文化教育等多个行业得到广泛使用，成为国内领先的网页防篡改产品。
        </p>
        <p>
          2007 年，天存信息成功研发了国内首个 Web 应用防护软件——iWall 应用防火墙，成功地解决了以注入式攻击为代表的各种 Web 应用层攻击给网站带来的巨大威胁。产品推向市场后，深受用户好评，通过将防篡改和应用防护的结合，带动了国内 Web 应用防火墙市场的发展。
        </p>
        <p>
          近些年来，天存信息在 Web 应用安全领域不断探索，相继推出页面行为审计、网页监测服务、交互业务防护等定制化和前瞻性的产品，为用户提供专业化的价值，同时在 Web 应用攻击方式和防护手段上积累了大量的经验。
        </p>
        <p>
          天存信息始终坚持以技术为先导，以产品为核心，持续追踪 Web 安全动态和更广泛的 Web 领域的技术发展，并保持在跨平台开发、高效底层通信、高性能高稳定服务器软件方面的卓越技术实力。
        </p>
        <p>
          目前，天存信息通过全国多家办事处和数十家经销商，已建立了覆盖全国的销售和服务网络。这些网络中的销售和技术支持人员，将以天存信息的优秀产品结合他们丰富的实践经验和细致的工作态度，为各行业用户的 Web 网站和 Web 应用保驾护航。
        </p>
      </div>
      <div class="swiper-content">
        <img
          src="@/assets/intro/arrow.png"
          alt=""
          @click="handlePrev"
          class="arrow">
        <Swiper
          ref="mySwiper"
          :options="swiperOptions">
          <SwiperSlide v-for="(item) in 5" :key="item">
            <div class="swiper-item-container">
              <img :src="require(`@/assets/intro/${item}.png`)" alt="">
            </div>
          </SwiperSlide>
        </Swiper>
        <img
          src="@/assets/intro/arrow.png"
          alt=""
          @click="handleNext"
          class="arrow">
      </div>
    </div>

    <!-- 底部 -->
    <Footer />
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 2.2,
        spaceBetween: 35,
        freeMode: true
      }
    }
  },
  methods: {
    handlePrev () {
      const swiper = this.$refs.mySwiper.$swiper
      swiper.slidePrev()
    },
    handleNext () {
      const swiper = this.$refs.mySwiper.$swiper
      swiper.slideNext()
    }
  }
}
</script>
<style lang="scss" scoped>
.intro__header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 126px;
  left: 322px;
  font-size: 40px;
  font-weight: 700;
  padding-left: 323px;
  color: #fff;
  background: #FD8808;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  @media screen and (max-width: 750px) {
    font-size: 72px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    padding-left: 90px;
    height: 178px;
  }
}
.intro__main {
  padding: 50px 360px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 1px;
  color: #666666;

  @media screen and (max-width: 750px) {
    padding: 50px 80px;
    text-align: justify;
    font-size: 45px;
  }

  p + p {
    padding-top: 30px;
    text-align: justify;
  }
}
.swiper-content {
  display: flex;
  align-items: center;
  margin: 50px 360px;
  padding: 35px 12px;
  background: #F8F8F8;
  box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
  border-radius: 12px;

  @media screen and (max-width: 750px) {
    margin: 0 80px;

    .arrow {
      display: none;
    }
  }

  .arrow {
    width: 29px;
    height: 84px;
    padding-right: 35px;
    cursor: pointer;

    &:nth-of-type(2) {
      transform: rotate(180deg);
    }
  }

  .swiper-item-container {
    width: 450x;

    @media screen and (max-width: 750px) {
      width: 520px;
    }

    img {
      width: 100%;
      box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);;
    }
  }
}
</style>
